import React from "react"
import Layout from "src/component/layout/layout"
import { StaticImage } from "gatsby-plugin-image"
import AiCasestudyListLatest3posts from "src/component/list/aiCasestudyListLatest3posts"
import { Seo } from "src/component/common/seo"
import { graphql, Link } from "gatsby"
import AiAgentsLogo from '../../assets/img/pages/top/logo_ai_agents.svg';

export default function AiAgents({ data }) {
        const filteredItems = data.allMicrocmsAiServices.edges.filter(({ node }) =>
        node.category.some(cat => cat.id === "ai-agents")
        )
        React.useEffect(() => {
        let js_scroll = document.getElementsByClassName('js-scroll');
        const scroll = () => {
            for (let i = 0; i < js_scroll.length; i++) {
                if ((window.pageYOffset + (window.innerHeight / 1.5)) > (window.pageYOffset + js_scroll[i].getBoundingClientRect().top)) {
                    js_scroll[i].classList.add('is-active');
                }
            }
        };
        scroll();
        window.addEventListener('scroll', () => {
            scroll();
        })
    }, [])
    return (
        <>
            <Layout>
              <div className="ai-series-wrap">
                <div className="ai-series">
                    <div className="ai-series__inner c-inner-primary">
                            <h1 className="ai-series__title">
                                <img src={AiAgentsLogo} alt="AI Agents" className="title-logo" />
                            </h1>
                            <div className="ai-series__item__catchcopy title-bold-catchcopy c-pc js-scroll">
                                <div><div><span>対話でデータ分析を完結させるAIサービス</span></div></div>
                            </div>
                            <div className="ai-series__item__catchcopy title-bold-catchcopy c-sp js-scroll">
                                <div><div><span>対話でデータ分析を</span></div></div>
                                <div><div><span>完結させるAIサービス</span></div></div>
                        </div>
                        
                        <div className="ai-series__policy">
                            <p className="ai-series__lead">
                                AIとの対話をもとに、大量データをリアルタイムに処理し、
                                <br />
                                チャットを通じてグラフや報告書を自動生成して瞬時に提供することで、
                                <br />
                                データ分析業務を効率的に支援します。
                               </p>
                            <p className="ai-series__lead">
                                ナレッジの蓄積や分析レポートの共有によるデータ分析の属人化や人材不足の課題を解決し、
                                <br />
                                効率性と品質を向上を実現。現場から意思決定者まで円滑に協働し、<br />
                                予防策や改善策を素早く検討することはもちろん、組織全体でのデータ活用を強力に後押しします。
                                
                            </p>
                       </div>
                    </div>
                </div>

               

                <section className="ai-series-features">
                    <div className="ai-series-features__inner c-inner-primary">
                        <ul>
                            <li className="ai-series-features__feature">
                                <div className="ai-series-features__feature__title">POINT 01</div>
                                <h3>細かい指示なしで自動化する高効率なデータ分析ワークフロー</h3>
                                <p>大量の情報を一括で取り込み、チャットを通じてレポートやグラフを瞬時に作成。
                                    <br />
                                    <br />
                                    これにより、ナレッジ共有や属人化の問題を解消すると同時に、短時間で質の高いインサイトを得られるようになり、組織全体の生産性が大幅に高まります。
                                </p>
                            </li>
                            <li className="ai-series-features__feature">
                                <div className="ai-series-features__feature__title">POINT 02</div>
                                <h3>会話形式での直感的な操作と応答</h3>
                                <p>直感的に操作できる会話型インターフェースを備えており、専門知識がなくても自然な言葉でAIを活用できます。
                                    <br />
                                    <br />
                                    ユーザーが質問や指示を入力すると、AIが即座に応答し、分析結果や最適な提案を提示します。誰でも高度なAI技術をスムーズに利用することが可能です。
                                </p>
                            </li>
                            <li className="ai-series-features__feature">
                                <div className="ai-series-features__feature__title">POINT 03</div>
                                <h3>業務の特性を理解したAIエージェントを開発</h3>
                                <p>対象業務に特化したアルゴリズムがデータを学習し、業務の条件や制約を考慮しながら、継続的に最適な意思決定を行います。
                                    <br />
                                    <br />
                                    この仕組みにより、業務プロセスの精度が向上し、効率的な運用が可能になります。
                                </p>
                            </li>
                        </ul>
                     
                    </div>
                    </section>
                    
                {/* 機能追加するcode DON'T delete */}
                {/* <section className="ai-functions">
                    <div className="ai-functions__inner c-inner-primary">
                        <div className="ai-functions__title c-title01">
                            <h2 className="ai-functions__title__text c-title01__text">機能紹介</h2>
                        </div>

                     <ul className="ai-functions__list">
                        {filteredItems.map(({ node }) => (
                            <li key={node.id}>
                                <div className="ai-functions__list__image">
                                    <div className="ai-functions__list__image__inner">
                                        <img src={node.thumnail.url} alt={node.title} width={node.thumnail.width} height={node.thumnail.height} />
                                    </div>
                                </div>
                                <div 
                                    className={`ai-functions__list__text ${node.button && node.link ? 'has-button' : 'no-button'}`} 
                                >
                                    <h3 className="ai-functions__list__title">
                                        <span className="is-title">{node.title}</span>
                                    </h3>
                                    <p className="ai-functions__list__lead">{node.content}</p>
                                </div>
                                {node.button && node.link && (
                                    <div className="ai-functions__list__button">
                                        <div className="ai-functions__list__button__inner">
                                            <Button external path={node.link} className="c-button01" label="詳しく見る" />
                                        </div>
                                    </div>
                                )}
                            </li>
                          ))}
                      </ul>

                    </div>
                </section> */}
                

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                         <AiCasestudyListLatest3posts />
                    </div>
                </section>
              </div>
            </Layout>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="AI AGENTS"
            description=""
            pathname=""
        />
    )
}

export const query = graphql`
    query {
        allMicrocmsAiServices {
            edges {
                node {
                    id
                    createdAt
                    updatedAt
                    title
                    content
                    thumnail {
                        url
                        width
                        height
                    }
                    category {
                        id
                        name
                    }
                    button
                    link
                }
            }
        }
    }
`